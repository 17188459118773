import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

const modalContent = {
  form: {
    heading: 'Dynamically Generated Forms',
    text1: `Turn on this setting if you’d like this form to only show if a user has
        added a specific item to their cart. The form will appear multiple times
        based on the number of times the item is added to the cart.`,
    text2: (
      <>
        In order to link this form to an item, the item must either be a ticket
        or meet the following criteria:
        <br />• Item must include at least one required question
        <br />• Item cannot have a quantity selector.
      </>
    ),
    subHeading: 'Let’s look at an example collection for a kids’ camp:',
    subText1: `One of the items on this collection is for camp registration dues. In
          our example, a parent has two children and added two camp
          registrations to their cart.`,
    subText2: `We need an information form to show only for those who have added this
          registration to their cart and we need this form filled out for each
          registrant. With a dynamically generated form, the parent will see two
          forms on the “forms” step before checkout: one for each child.`,
    actionLink: '#',
  },
  waiver: {
    heading: 'Dynamically Generated Waivers',
    text1: `Turn on this setting if you’d like this waiver to only show if a user has added a specific item to their cart.
            The waiver will appear multiple times based on the item’s quantity.`,
    text2: null,
    subHeading: 'Let’s look at an example collection for a company event:',
    subText1: `The collection includes several items and one of them is for an optional trapeze lesson.
              In our example, an employee has added two trapeze lessons to their cart: one for herself and one for her spouse.`,
    subText2: `We need a liability waiver to show only for those who have added this lesson to their cart and we need this waiver for each registrant.
            In our example, the employee will see two waivers on the “forms” step before checkout: one for her and one for her spouse.
    `,
    actionLink: '#',
  },
}

interface DynamicallyGeneratedFormsModalProps extends WebUI.ModalProps {
  formType?: 'waiver' | 'form'
}

const DynamicallyGeneratedFormsModal = React.forwardRef<
  WebUI.DialogInstance,
  DynamicallyGeneratedFormsModalProps
>(
  (
    {initialVisible = false, className, formType = 'form', ...restProps},
    forwardedRef,
  ) => (
    <WebUI.Modal
      ref={forwardedRef}
      aria-label="Item Types View"
      className={WebUI.cn(
        '[&_>_.ModalContentView]:max-w-2xl [&_>_.ModalContentView]:overflow-auto [&_>_.ModalContentView]:px-9 [&_>_.ModalContentView]:py-12',
        className,
      )}
      initialVisible={initialVisible}
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <div className="flex flex-col gap-7">
        <WebUI.Heading as="h2">{modalContent[formType].heading}</WebUI.Heading>
        <WebUI.Text className="font-light">
          {modalContent[formType].text1}
        </WebUI.Text>
        {modalContent[formType].text2 && (
          <WebUI.Text className="font-light">
            {modalContent[formType].text2}
          </WebUI.Text>
        )}
        <div className="flex flex-col gap-5 bg-teal-90 p-7">
          <WebUI.Text className="font-extrabold text-ds-sm">
            {modalContent[formType].subHeading}
          </WebUI.Text>
          <WebUI.Text className="font-light text-ds-sm">
            {modalContent[formType].subText1}
          </WebUI.Text>
          <WebUI.Text className="font-light text-ds-sm">
            {modalContent[formType].subText2}
          </WebUI.Text>
          <WebUI.Anchor
            className="text-ds-sm"
            href={modalContent[formType].actionLink}
          >
            See this in action
          </WebUI.Anchor>
        </div>
      </div>
    </WebUI.Modal>
  ),
)

export default DynamicallyGeneratedFormsModal
