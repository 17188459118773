import React, {useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from '@cheddarup/react-util'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreateFormMutation,
  useUpdateFormMutation,
} from '@cheddarup/api-client'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'
import WaiverFormIcon from 'src/images/WaiverFormIcon.svg'
import {
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'
import {WaiverFormSettings} from './components'
import type {FormFormValues} from './FormFormPage'
import {FieldGroupsPreview} from 'src/components/FieldsEdit/FieldGroupsPreview'
import {makeUuid} from '@cheddarup/util'
import {UnsavedField} from 'src/components/FieldsEdit/FieldsEdit'

export interface AddWaiverPageProps {
  waiver?: Api.TabForm | null
}

interface WaiverFormValues extends Omit<FormFormValues, 'required'> {
  description: string
}

export type WaiverFormFormik = ReturnType<typeof useFormik<WaiverFormValues>>

const WAIVER_FORM_DEFAULT_FIELDS: UnsavedField[] = [
  {name: 'First Name', fieldType: 'text', fieldIdentifier: 'first_name'},
  {name: 'Last Name', fieldType: 'text', fieldIdentifier: 'last_name'},
  {name: 'Signed Date', fieldType: 'date'},
  {name: 'Signature', fieldType: 'signature'},
].map((field, index) => ({
  name: field.name,
  field_type: field.fieldType as Api.TabObjectFieldType,
  required: true,
  position: index,
  metadata: {
    fieldSetId: makeUuid(),
    fieldTypeMetadata: {
      timeZone: '',
      ...(field.fieldIdentifier && {
        fieldIdentifier: field.fieldIdentifier as Api.TabObjectFieldIdentifier,
      }),
    },
  },
}))

const AddWaiverPage = ({waiver}: AddWaiverPageProps) => {
  const [selectedTabId, setSelectedTabId] = useState('details')
  const navigate = useNavigate()
  const urlParams = useParams()
  const dialogDefaultHideRef = useRef<(() => void) | null>(null)
  const alertRef = useRef<WebUI.DialogInstance>(null)
  const tabsRef = useRef<WebUI.TabsInstance>(null)

  const createFormMutation = useCreateFormMutation()
  const updateFormMutation = useUpdateFormMutation()
  const {data: collection} = api.tabs.detail.useSuspenseQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })

  const collectionId = Number(urlParams.collection)
  const isBasic = !collection?.is_pro && !collection?.is_team
  const enforceAddlGatFeatures = !collection?.options?.doNotEnforceAddlGated
  const showPaidBadge = isBasic && enforceAddlGatFeatures

  const formik = useFormik<WaiverFormValues>({
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Waiver Name is required'),
    }),
    initialValues: {
      name: waiver?.name ?? '',
      description: waiver?.description ?? '',
      linked_item_id: waiver?.linked_item_id ?? null,
      options: {
        linkedItem: {fieldId: waiver?.options.linkedItem?.fieldId ?? null},
      },
    },
    onSubmit: async (values) => {
      const payload = {
        ...values,
        options: {
          ...waiver?.options,
          ...(values.linked_item_id && formik.values.options),
          isWaiver: true,
        },
      }
      if (waiver) {
        await updateFormMutation.mutateAsync({
          pathParams: {
            tabId: collectionId,
            formId: waiver.id,
          },
          body: {...waiver, ...payload},
        })
      } else {
        await createFormMutation.mutateAsync({
          pathParams: {
            tabId: collectionId,
          },
          body: {
            ...payload,
            required: true,
          },
        })
      }

      navigate('..')
    },
  })

  if (isBasic && collection?.status !== 'draft' && enforceAddlGatFeatures) {
    return <Navigate to="../i/plans" />
  }

  return (
    <PremiumFeaturesSideSheet
      tabId={collectionId}
      enforcedPremiumMeta={{pro: {waiver: true}}}
    >
      <WebUI.Modal
        ref={(dialog) => {
          if (!dialog) {
            return
          }

          dialogDefaultHideRef.current = dialog.hide
          dialog.hide = () => {
            if (formik.dirty) {
              alertRef.current?.show()
            } else {
              dialogDefaultHideRef.current?.()
            }
          }
        }}
        className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
        onDidHide={() => navigate('..')}
      >
        {(dialog) => (
          <>
            <WebUI.ModalCloseButton />
            <WebUI.ModalHeader className="border-b-0 px-8 pt-10 pb-0">
              <WebUI.PageHeader
                graphics={!showPaidBadge && <img src={WaiverFormIcon} alt="" />}
                subheading="Four required fields (first name, last name, date and e-signature) will be added below your waiver text automatically."
              >
                <div className="flex gap-4">
                  {waiver ? 'Edit Waiver' : 'Add Waiver'}
                  {showPaidBadge && (
                    <PremiumFeatureSideSheetDisclosure
                      className="mb-1"
                      featureKeys={['waiver']}
                    />
                  )}
                </div>
              </WebUI.PageHeader>
            </WebUI.ModalHeader>
            <form onSubmit={formik.handleSubmit} className="flex min-h-0 grow">
              <WebUI.Tabs
                ref={tabsRef}
                className="grow [&_>_.TabPanel:overflow-y-auto [&_>_.TabPanel]:grow [&_>_.TabPanel]:p-8"
                variant="underlined"
                onChangeSelectedId={(newSelectedId) => {
                  if (newSelectedId != null) {
                    setSelectedTabId(newSelectedId)
                  }
                }}
              >
                <WebUI.TabList
                  aria-label="Waiver form navigation"
                  className="mx-6 flex-0 border-b-0 sm:mx-13 [&_>_.TabList-underline]:bg-orange-50 [&_>_.Tab_>_.Button-content]:text-ds-sm sm:[&_>_.Tab_>_.Button-content]:text-ds-md"
                >
                  <WebUI.Tab id="details">Waiver</WebUI.Tab>
                  <WebUI.Tab id="settings">Settings</WebUI.Tab>
                  <WebUI.Tab id="preview">Preview</WebUI.Tab>
                </WebUI.TabList>

                <WebUI.Separator variant="primary" />

                <WebUI.TabPanel id="details" className="flex flex-col gap-6">
                  <WebUI.FormField error={formik.errors.name}>
                    <WebUI.Input
                      name="name"
                      placeholder="Waiver Name (required)"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>

                  <WebUI.RichTextEditor
                    className="min-h-[200px]"
                    name="description"
                    placeholder="Waiver Text"
                    initialMarkdownValue={formik.values.description}
                    onMarkdownValueChange={(newDescription) =>
                      formik.setFieldValue('description', newDescription)
                    }
                    disabled={false}
                    readOnly={false}
                  >
                    <WebUI.RichTextEditorToolbar rootClassName="-order-1" />
                  </WebUI.RichTextEditor>
                </WebUI.TabPanel>
                <WebUI.TabPanel id="settings">
                  <WaiverFormSettings tabId={collectionId} formik={formik} />
                </WebUI.TabPanel>
                <WebUI.TabPanel
                  id="preview"
                  className="flex flex-col gap-4 overflow-auto"
                >
                  <div className="flex flex-col gap-1 sm:flex-row sm:items-center">
                    <WebUI.Heading as="h2">
                      {`${formik.values.name}${formik.values.linked_item_id ? ':' : ''}`}
                    </WebUI.Heading>
                    {formik.values.linked_item_id && (
                      <WebUI.Button
                        variant="secondaryAlt"
                        size="compact"
                        className="self-start bg-teal-80 text-tint"
                      >
                        + Dynamic Naming
                      </WebUI.Button>
                    )}
                  </div>
                  <WebUI.Separator />
                  {formik.values.description && (
                    <WebUI.MarkdownParagraph
                      markdown={formik.values.description}
                    />
                  )}
                  <div className="max-w-xl">
                    <FieldGroupsPreview fields={WAIVER_FORM_DEFAULT_FIELDS} />
                  </div>
                </WebUI.TabPanel>
              </WebUI.Tabs>
            </form>

            <WebUI.PageToolbar>
              {!waiver && selectedTabId !== 'preview' ? (
                <WebUI.Button
                  variant="default"
                  size="large"
                  onClick={() => tabsRef.current?.next()}
                >
                  Continue
                </WebUI.Button>
              ) : (
                <WebUI.PageToolbarSubmitButton
                  arrow={false}
                  onClick={() => formik.submitForm()}
                  loading={formik.isSubmitting}
                >
                  Save Waiver
                </WebUI.PageToolbarSubmitButton>
              )}
            </WebUI.PageToolbar>

            <UpgradeRequiredAlert
              visible={
                !waiver &&
                collection &&
                collection.status !== 'draft' &&
                !collection.is_pro &&
                collection.reportsAvailable.activeFormsCount +
                  collection.reportsAvailable.activeSignupsCount >=
                  1
              }
              onDidHide={() => dialog.hide()}
            />
            <DirtyFormConfirmAlert
              ref={alertRef}
              onProceed={() => dialogDefaultHideRef.current?.()}
            />
          </>
        )}
      </WebUI.Modal>
    </PremiumFeaturesSideSheet>
  )
}

// MARK: – DirtyFormConfirmAlert

export interface DirtyFormConfirmAlertProps extends WebUI.AlertProps {
  onProceed?: () => void
}

export const DirtyFormConfirmAlert = React.forwardRef<
  WebUI.DialogInstance,
  DirtyFormConfirmAlertProps
>(({onProceed, className, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    ref={forwardedRef}
    aria-label="Confirm closing waiver form modal"
    className={WebUI.cn('[&_.Alert-closeButton]:invisible', className)}
    {...restProps}
  >
    {(dialog) => (
      <>
        <WebUI.AlertHeader>
          Are you sure you want to close this waiver?
        </WebUI.AlertHeader>
        <WebUI.AlertContentView
          text="You haven’t saved your waiver and your information will be lost."
          actions={
            <>
              <WebUI.AlertActionButton onClick={() => onProceed?.()}>
                Close Waiver
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton
                onClick={(event) => {
                  event.preventDefault()
                  dialog.hide()
                }}
              >
                Cancel
              </WebUI.AlertCancelButton>
            </>
          }
        />
      </>
    )}
  </WebUI.Alert>
))

export default AddWaiverPage
