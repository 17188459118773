import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {PremiumFeaturesSideSheet} from 'src/components/PremiumFeaturesSideSheet'

import {SettingDisclosureSwitch} from '../../CollectionSettingsPage/SettingDisclosureSwitch'
import {CollectionSettingsUpgradePlanButton} from '../../CollectionSettingsPage/CollectionSettingsUpgradePlanButton'
import DynamicallyGeneratedFormsModal from './DynamicallyGeneratedFormsModal'
import DynamicFormNamesModal from './DynamicFormNamesModal'
import type {WaiverFormFormik} from '../AddWaiverPage'
import {ConditionalWrapper} from '@cheddarup/react-util'

export interface WaiverFormSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: WaiverFormFormik
  tabId: number
}

const WaiverFormSettings = ({
  formik,
  tabId,
  className,
  ...restProps
}: WaiverFormSettingsProps) => {
  const [enforcedPremiumMeta, setEnforcedPremiumMeta] = useState<
    Util.PartialDeep<Api.TabPremiumMeta>
  >({})

  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      tabId,
    },
  })
  const tabItemsQuery = api.tabItems.list.useQuery(
    {pathParams: {tabId}, queryParams: {has_required_fields: true}},
    {
      select: (items) =>
        items.filter(
          (item) =>
            !item.allow_quantity || item.options.itemSubType === 'ticket',
        ),
    },
  )

  const linkedItemQuery = api.tabItems.detail.useQuery(
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    {pathParams: {tabId, itemId: formik.values.linked_item_id!}},
    {enabled: !!formik.values.linked_item_id},
  )
  const isFormsFirst = tabQuery.data?.options?.displayOrder?.[0] === 'forms'
  const isTeamCollection = tabQuery.data?.is_team
  const dynamicFormSettingSwitchContent = (
    <div className="flex flex-col gap-1">
      <WebUI.Text>
        Dynamically generate waiver based on an item and its quantity
      </WebUI.Text>
      <WebUI.Text className="text-ds-sm">
        Have this waiver dynamically appear multiple times based on the number
        of times the item is added to the payer’s cart.{' '}
        <DynamicallyGeneratedFormsModal
          disclosure={
            <WebUI.DialogDisclosure variant="link">
              Learn More
            </WebUI.DialogDisclosure>
          }
          formType="waiver"
        />
      </WebUI.Text>
    </div>
  )

  return (
    <PremiumFeaturesSideSheet
      tabId={tabId}
      enforcedPremiumMeta={enforcedPremiumMeta}
    >
      <div
        className={WebUI.cn(
          '[&_>_.Separator]:-mx-2 flex flex-col gap-6',
          className,
        )}
        {...restProps}
      >
        <WebUI.Disclosure
          className="gap-4"
          initialVisible={!!formik.values.linked_item_id}
          onVisibleChange={(enabled) => {
            setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
              ...prevEnforcedPremiumMeta,
              team: {
                ...prevEnforcedPremiumMeta?.team,
                dynamicLinkedItems: enabled,
              },
            }))
            if (!enabled) {
              formik.setFieldValue('linked_item_id', null)
            }
          }}
        >
          {(disclosure) => (
            <>
              <ConditionalWrapper
                condition={isFormsFirst || tabItemsQuery.data?.length === 0}
                wrapper={(children) => (
                  <WebUI.Tooltip placement="top-end">
                    <WebUI.TooltipAnchor>{children}</WebUI.TooltipAnchor>
                    <WebUI.TooltipContent variant="light" className="max-w-64">
                      {isFormsFirst
                        ? 'This feature is not available because you’ve enabled the display option of showing forms before items.'
                        : 'To link this form to an item, your item must have at least one required question and quantity selector disabled.'}
                    </WebUI.TooltipContent>
                  </WebUI.Tooltip>
                )}
              >
                {(tabQuery.data && tabQuery.data.status === 'draft') ||
                isTeamCollection ? (
                  <SettingDisclosureSwitch
                    featureKey={
                      isTeamCollection ? undefined : 'dynamicLinkedItems'
                    }
                    checked={
                      !!formik.values.linked_item_id || disclosure.visible
                    }
                    disabled={isFormsFirst || tabItemsQuery.data?.length === 0}
                  >
                    {dynamicFormSettingSwitchContent}
                  </SettingDisclosureSwitch>
                ) : (
                  <CollectionSettingsUpgradePlanButton plan="team">
                    {dynamicFormSettingSwitchContent}
                  </CollectionSettingsUpgradePlanButton>
                )}
              </ConditionalWrapper>

              <WebUI.DisclosureContent>
                <div className="flex flex-col gap-7">
                  <WebUI.FormField
                    className="max-w-96"
                    label="Select an item to link this waiver to:"
                    required
                  >
                    <WebUI.DropdownSelect
                      placeholder="Select an item"
                      value={formik.values.linked_item_id}
                      loading={tabItemsQuery.isLoading}
                      onValueChange={(tabItemId) =>
                        formik.setFieldValue('linked_item_id', tabItemId)
                      }
                    >
                      {tabItemsQuery.data?.map((tItem) => (
                        <WebUI.DropdownSelectOption
                          key={tItem.id}
                          value={tItem.id}
                        >
                          {tItem.name}
                        </WebUI.DropdownSelectOption>
                      ))}
                    </WebUI.DropdownSelect>
                  </WebUI.FormField>
                  {formik.values.linked_item_id && (
                    <>
                      <WebUI.Separator />
                      <WebUI.Text className="text-ds-sm">
                        Your waiver’s name will be dynamically populated with a
                        payer’s response to a required question.{' '}
                        <DynamicFormNamesModal
                          formType="waiver"
                          disclosure={
                            <WebUI.DialogDisclosure variant="link">
                              See example
                            </WebUI.DialogDisclosure>
                          }
                        />
                      </WebUI.Text>
                      <WebUI.FormField
                        className="max-w-96"
                        label="Select from your required questions below:"
                        required
                      >
                        <WebUI.DropdownSelect
                          placeholder="Select a required question"
                          value={formik.values.options.linkedItem.fieldId}
                          loading={linkedItemQuery.isLoading}
                          onValueChange={(linkedItemFieldId) =>
                            formik.setFieldValue(
                              'options.linkedItem.fieldId',
                              linkedItemFieldId,
                            )
                          }
                        >
                          {linkedItemQuery.data?.fields.map((field) => (
                            <WebUI.DropdownSelectOption
                              key={field.id}
                              value={field.id}
                            >
                              {field.name}
                            </WebUI.DropdownSelectOption>
                          ))}
                        </WebUI.DropdownSelect>
                      </WebUI.FormField>
                    </>
                  )}
                </div>
              </WebUI.DisclosureContent>
            </>
          )}
        </WebUI.Disclosure>
        <WebUI.Separator />
      </div>
    </PremiumFeaturesSideSheet>
  )
}

export default WaiverFormSettings
