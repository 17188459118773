import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {ConditionalWrapper} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import {PremiumFeaturesSideSheet} from 'src/components/PremiumFeaturesSideSheet'

import {SettingDisclosureSwitch} from '../../../CollectionSettingsPage/SettingDisclosureSwitch'
import {CollectionSettingsUpgradePlanButton} from '../../../CollectionSettingsPage/CollectionSettingsUpgradePlanButton'
import type {FixedItemFormFormik} from '../containers/FixedItemForm/FixedItemForm'
import {ItemQuantityGroupsModal} from '../../ItemsPage/components'
import {FormSettingsSwitch} from 'src/components'

export interface ItemFormAdvancedSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: FixedItemFormFormik
  tabId: number
  item?: Api.TabItem
}

const ItemFormAdvancedSettings = ({
  formik,
  tabId,
  item,
  className,
  ...restProps
}: ItemFormAdvancedSettingsProps) => {
  const [enforcedPremiumMeta, setEnforcedPremiumMeta] = useState<
    Util.PartialDeep<Api.TabPremiumMeta>
  >({})
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      tabId,
    },
  })
  const inventoryGroupsQuery = api.inventoryGroups.list.useQuery(
    {
      pathParams: {
        tabId,
      },
    },
    {
      select: (inventoryGroups) =>
        inventoryGroups.filter((ig) =>
          ig.inventory_items.some((ii) => ii.tab_object_id === item?.id),
        ),
    },
  )

  const isTeamCollection = tabQuery.data?.is_team
  const waitlistDescription = `Once ${
    formik.values.options.variants.enabled ? 'a variation' : 'your item'
  } is sold out, create a list of who wants to purchase this item so you can contact them if more become available. ${
    formik.values.options.variants.enabled
      ? '(Note that you must have quantities assigned to your variations in order to create a waitlist.)'
      : ''
  }`

  return (
    <PremiumFeaturesSideSheet
      tabId={tabId}
      enforcedPremiumMeta={enforcedPremiumMeta}
    >
      <WebUI.VStack
        className={WebUI.cn('[&_>_.Separator]:-mx-2 gap-6', className)}
        {...restProps}
      >
        <>
          <FormSettingsSwitch
            name="allow_quantity"
            title="Show a quantity selector"
            description="Allow payers to buy more than one of this item. (Not recommended if you add item questions.)"
            checked={formik.values.allow_quantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <WebUI.Separator />
        </>
        {!formik.values.options.variants.enabled && (
          <>
            <WebUI.VStack className="gap-2">
              <FormSettingsSwitch
                name="available_quantity_enabled"
                title="Set available quantity"
                description="“Sold out” will show when all available items have been purchased."
                checked={formik.values.available_quantity_enabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="flex flex-row items-center gap-4">
                {formik.values.available_quantity_enabled && (
                  <WebUI.FormField
                    className="mx-3"
                    label="Total Available"
                    required
                    error={formik.errors.available_quantity}
                  >
                    <WebUI.Input
                      className="max-w-32"
                      name="available_quantity"
                      placeholder="Qty."
                      type="number"
                      value={formik.values.available_quantity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                )}
                <ItemQuantityGroupsModal
                  disclosure={
                    <div
                      className={WebUI.cn(
                        'mt-[29px] flex flex-col gap-0_5 py-1',
                        !!formik.errors.available_quantity && 'mb-[26px]',
                      )}
                    >
                      {inventoryGroupsQuery.data?.map((ig) => (
                        <WebUI.DialogDisclosure
                          key={ig.id}
                          className="font-light text-ds-sm"
                          variant="link"
                        >
                          {ig.name || 'Grouped quantity'}:{' '}
                          {ig.available_quantity}
                        </WebUI.DialogDisclosure>
                      ))}
                    </div>
                  }
                  collectionId={tabId}
                />
              </div>
            </WebUI.VStack>
            <WebUI.Separator />
          </>
        )}

        {!formik.values.options.variants.enabled &&
          formik.values.available_quantity_enabled && (
            <>
              <FormSettingsSwitch
                name="options.makeAvailableQuantityPublic"
                title="Make available quantity public"
                description="Payers will see how many items are left to purchase."
                checked={formik.values.options.makeAvailableQuantityPublic}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <WebUI.Separator />
            </>
          )}

        {(formik.values.options.variants.enabled ||
          formik.values.available_quantity_enabled) && (
          <>
            <WebUI.Disclosure
              className="gap-4"
              visible={formik.values.options.waitlist.enabled}
              onVisibleChange={(enabled) => {
                formik.setFieldValue('options.waitlist.enabled', enabled)
                setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
                  ...prevEnforcedPremiumMeta,
                  team: {
                    ...prevEnforcedPremiumMeta?.team,
                    waitlist: enabled,
                  },
                }))
              }}
            >
              {(tabQuery.data && tabQuery.data.status === 'draft') ||
              isTeamCollection ? (
                <SettingDisclosureSwitch
                  featureKey={
                    !isTeamCollection && formik.values.options.waitlist.enabled
                      ? 'waitlist'
                      : undefined
                  }
                  checked={formik.values.options.waitlist.enabled}
                  disabled={
                    formik.values.options.variants.enabled &&
                    !formik.values.options.variants.listings?.some(
                      (l) => l.available_quantity !== '',
                    )
                  }
                >
                  <WebUI.VStack>
                    <WebUI.Text> Create a waitlist</WebUI.Text>
                    <span className="text-ds-sm">{waitlistDescription}</span>
                  </WebUI.VStack>
                </SettingDisclosureSwitch>
              ) : (
                <CollectionSettingsUpgradePlanButton plan="team">
                  <WebUI.VStack className="gap-1">
                    <WebUI.Text> Create a waitlist</WebUI.Text>
                    <span className="text-ds-sm">{waitlistDescription}</span>
                  </WebUI.VStack>
                </CollectionSettingsUpgradePlanButton>
              )}

              <WebUI.DisclosureContent>
                <WebUI.VStack className="gap-2">
                  <WebUI.FormField
                    className="mx-3"
                    label="Custom Message"
                    error={formik.errors.options?.waitlist?.customMessage}
                  >
                    <WebUI.RichTextEditor
                      className="min-h-[112px] max-w-[384px] text-ds-sm"
                      name="options.waitlist.customMessage"
                      placeholder="Optional: Share details with people who join your waitlist (e.g. when they can expect to hear from you, who to contact with questions, etc.)."
                      initialMarkdownValue={
                        formik.values.options.waitlist.customMessage
                      }
                      onMarkdownValueChange={(newDescription) =>
                        formik.setFieldValue(
                          'options.waitlist.customMessage',
                          newDescription,
                        )
                      }
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                </WebUI.VStack>
              </WebUI.DisclosureContent>
            </WebUI.Disclosure>
            <WebUI.Separator />
          </>
        )}

        {!formik.values.options.variants.enabled && (
          <>
            <WebUI.VStack className="gap-2">
              <FormSettingsSwitch
                name="limit_per_person_quantity_enabled"
                title="Limit per-person quantity"
                description="Limit the quantity of this item that each payer can add to their cart."
                checked={formik.values.limit_per_person_quantity_enabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.values.limit_per_person_quantity_enabled && (
                <WebUI.FormField
                  className="mx-3"
                  label="Per-Person Maximum Quantity"
                  error={formik.errors.limit_per_person_quantity}
                >
                  <WebUI.Input
                    className="max-w-32"
                    name="limit_per_person_quantity"
                    placeholder="Qty."
                    type="number"
                    value={formik.values.limit_per_person_quantity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
              )}
            </WebUI.VStack>
            <WebUI.Separator />
          </>
        )}
        <ConditionalWrapper
          condition={!!item?.hidden}
          wrapper={(children) => (
            <WebUI.Tooltip>
              <WebUI.TooltipAnchor>{children}</WebUI.TooltipAnchor>
              <WebUI.TooltipContent>
                Items cannot be both hidden and required
              </WebUI.TooltipContent>
            </WebUI.Tooltip>
          )}
        >
          <FormSettingsSwitch
            name="required"
            title="Make item required"
            disabled={!!item?.hidden}
            description={
              <div>
                <span className="font-bold">
                  Only toggle this on if this item is required for every payer.
                </span>{' '}
                <span className="font-light">
                  Payers will not be able to proceed to checkout without adding
                  this item to their cart.
                </span>
              </div>
            }
            checked={formik.values.required}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </ConditionalWrapper>
      </WebUI.VStack>
    </PremiumFeaturesSideSheet>
  )
}

export default ItemFormAdvancedSettings
