import * as WebUI from '@cheddarup/web-ui'

export interface FormSettingsSwitchProps
  extends Omit<WebUI.SwitchProps, 'title'> {
  title: React.ReactNode
  description: React.ReactNode
}

export const FormSettingsSwitch: React.FC<FormSettingsSwitchProps> = ({
  title,
  description,
  className,
  ...restProps
}) => (
  <WebUI.Switch
    className={WebUI.cn('FormSettingsSwitch gap-3', className)}
    reverse
    {...restProps}
  >
    <WebUI.VStack className="FormSettingsSwitch-inner gap-0_5">
      <WebUI.Text>{title}</WebUI.Text>
      <div className="text-ds-sm">{description}</div>
    </WebUI.VStack>
  </WebUI.Switch>
)

export default FormSettingsSwitch
