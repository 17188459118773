import * as WebUI from '@cheddarup/web-ui'
import React, {useImperativeHandle, useMemo, useRef, useState} from 'react'
import {useUpdateEffect} from '@cheddarup/react-util'
import {LinkButton} from 'src/components/LinkButton'

import useCart from './hooks/useCart'
import usePublicCollection from './hooks/usePublicCollection'
import {AddPaymentFormViewsBanner} from './components/AddPaymentBanners'
import {PayerObjectsLayout} from './components/PayerLayouts'
import {isFormsBeforeItems} from './utils/public-collection-utils'
import {CollectionOverview} from './components/CollectionOverview'
import {
  getMissingRequiredForms,
  getMissingRequiredSignups,
  getPayerBrandKitColors,
  PayerBrandKitColors,
} from '@cheddarup/core'
import {usePayerUIState} from './PayerUIStateProvider'
import {getCUReadableColor} from 'src/helpers/color-utils'

const PayerFormsPage = () => {
  const {publicCollection} = usePublicCollection()
  const payerUIState = usePayerUIState()

  return (
    <PayerObjectsLayout>
      <AddPaymentFormViewsBanner />
      {isFormsBeforeItems(publicCollection) && <CollectionOverview />}
      <FormList ref={payerUIState.formPickerRef} />
    </PayerObjectsLayout>
  )
}

// MARK: – FormList

export interface FormListInstance {
  viewRequiredForms: () => void
}

export const FormList = React.forwardRef<
  FormListInstance,
  Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>
>(({className, ...restProps}, forwardedRef) => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [isInErrorMode, setIsInErrorMode] = useState(false)
  const listRef = useRef<WebUI.ListInstance>(null)
  const brandKitColors = getPayerBrandKitColors(
    publicCollection.organizer.branding?.color_palette.payerPage,
  )
  useImperativeHandle(
    forwardedRef,
    () => ({
      viewRequiredForms: () => {
        const [firstMissingRequiredFormOrSignup] = [
          ...getMissingRequiredForms({
            publicTab: publicCollection,
            cart,
          }),
          ...getMissingRequiredSignups({
            publicTab: publicCollection,
            cart,
          }),
        ]

        if (firstMissingRequiredFormOrSignup != null) {
          listRef.current?.scrollToRow(firstMissingRequiredFormOrSignup.id)
          setIsInErrorMode(true)
        }
      },
    }),
    [publicCollection, cart],
  )

  const listData = useMemo(() => {
    const dynamicForms =
      cart?.forms
        .filter((cForm) => !!cForm.detail.linked_item_id)
        .map((cForm) => ({
          ...publicCollection.forms.find(
            (form) => form.id === cForm.tab_form.id,
          ),
          name: cForm.detail.name,
          detail: cForm.detail,
        })) ?? []
    const dynamicFormIds = dynamicForms.map((dForm) => dForm.id)
    return [
      ...(dynamicForms as Api.PublicTabForm[]),
      ...publicCollection.forms.filter(
        (f) => f.fields.length > 0 && !dynamicFormIds.includes(f.id),
      ),
      ...publicCollection.signups.filter((f) => f.visible_spots.length > 0),
    ]
  }, [publicCollection.forms, publicCollection.signups, cart?.forms])

  const missingRequiredFormIds = useMemo(
    () =>
      getMissingRequiredForms({publicTab: publicCollection, cart}).map(
        (f) => f.id,
      ),
    [cart, publicCollection],
  )
  const missingRequiredSignUpIds = useMemo(
    () =>
      getMissingRequiredSignups({publicTab: publicCollection, cart}).map(
        (s) => s.id,
      ),
    [cart, publicCollection],
  )

  const missingRequiredForms = missingRequiredFormIds.length > 0
  const missingRequiredSignups = missingRequiredSignUpIds.length > 0

  useUpdateEffect(() => {
    if (!missingRequiredForms && !missingRequiredSignups) {
      setIsInErrorMode(false)
    }
  }, [missingRequiredForms, missingRequiredSignups])

  const FormListRowWithHandlers: WebUI.ListRowComponentType<
    Api.PublicTabForm | Api.PublicTabSignup
  > = useMemo(
    () =>
      React.forwardRef(({data: form, ...rowProps}, forwardedRef) => {
        const formInCart = cart?.forms.find(
          (cf) =>
            cf.tab_form.id === form.id &&
            cf.detail.linked_item_id === form.detail?.linked_item_id,
        )
        return (
          <FormListRow
            ref={forwardedRef}
            aria-invalid={
              isInErrorMode &&
              [...missingRequiredSignUpIds, ...missingRequiredFormIds].includes(
                form.id,
              )
            }
            isInCart={
              (formInCart &&
                (!formInCart.detail.linked_item_id ||
                  form.fields.some((fField) =>
                    formInCart.cart_field_views.some(
                      (cFieldView) => cFieldView.item_field_id === fField.id,
                    ),
                  ))) ||
              cart?.time_slots.some(
                (cTs) =>
                  'visible_spots' in form &&
                  form.visible_spots.some((s) =>
                    s.time_slots.some((ts) => cTs.time_slot.id === ts.id),
                  ),
              )
            }
            data={form}
            brandKitColors={brandKitColors}
            {...rowProps}
          />
        )
      }),
    [
      isInErrorMode,
      missingRequiredSignUpIds,
      missingRequiredFormIds,
      cart?.forms,
      cart?.time_slots,
      brandKitColors,
    ],
  )

  return (
    <WebUI.Panel variant="capsule">
      <WebUI.List
        ref={listRef}
        className="grow gap-4 overflow-visible py-3 sm:px-8 sm:py-6"
        data={listData}
        HeaderComponent={() => null}
        EmptyStateViewComponent={() => null}
        RowComponent={FormListRowWithHandlers}
        {...restProps}
      />
    </WebUI.Panel>
  )
})

// MARK: – FormListRow

interface FormListRowProps
  extends WebUI.ListRowComponentProps<Api.PublicTabForm | Api.PublicTabSignup> {
  isInCart?: boolean
  brandKitColors: PayerBrandKitColors
}

const FormListRow = React.forwardRef<HTMLDivElement, FormListRowProps>(
  (
    {isInCart, data, index, className, brandKitColors, ...restProps},
    forwardedRef,
  ) => {
    const isRequired =
      'signupType' in data.options && !data.options.requireAtLeastOneSpot
        ? false
        : data.required

    return (
      <WebUI.VStack
        ref={forwardedRef}
        className={WebUI.cn(
          'items-stretch aria-invalid:border-orange-50 sm:flex-row sm:items-center sm:rounded',
          className,
        )}
        as={WebUI.Panel}
        {...restProps}
      >
        <WebUI.VStack className="flex-auto px-4 pt-6 pb-0 sm:p-8">
          <WebUI.Text>
            {data.name}
            <span
              className="text-orange-50"
              style={{display: isRequired ? 'inline' : 'none'}}
            >
              *
            </span>
          </WebUI.Text>
          <WebUI.Text className="pt-1 text-ds-sm">
            {'isWaiver' in data.options && data.options.isWaiver
              ? 'Waiver'
              : 'signupType' in data.options
                ? 'Sign Up'
                : 'Form'}
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.Separator orientation="vertical" variant="primary" />
        <div className="p-4 sm:p-8">
          <LinkButton
            className="w-[130px]"
            variant={isInCart ? 'secondary' : 'default'}
            roundness="capsule"
            iconBefore={
              isInCart && (
                <WebUI.PhosphorIcon
                  className="text-ds-lg"
                  icon="check-circle-fill"
                  color={brandKitColors.primaryButton}
                />
              )
            }
            preserveSearch
            to={`form/${data.id}?linkedItemId=${data.detail?.linked_item_id}`}
            style={
              isInCart
                ? undefined
                : {
                    backgroundColor: brandKitColors.primaryButton,
                    color: getCUReadableColor(brandKitColors.primaryButton),
                  }
            }
          >
            {isInCart
              ? 'Edit'
              : `View ${
                  'isWaiver' in data.options && data.options.isWaiver
                    ? 'Waiver'
                    : 'signupType' in data.options
                      ? 'Sign Up'
                      : 'Form'
                }`}
          </LinkButton>
        </div>
      </WebUI.VStack>
    )
  },
)

export default PayerFormsPage
