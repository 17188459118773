import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreateCartMutation = makeUseMutation(
  endpoints.carts.create,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, {
        pathParams: {
          tabId: vars.pathParams.tabId,
          cartUuid: newCart.uuid,
        },
      }),
    ],
  }),
)

export const useUpdateCartMutation = makeUseMutation(
  endpoints.carts.update,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.carts.detail,
        (prevCart) =>
          prevCart
            ? {
                ...prevCart,
                shippingInfo: {
                  ...prevCart.shippingInfo,
                  shippingMethod:
                    vars.body?.shippingMethod ??
                    prevCart.shippingInfo.shippingMethod ??
                    'toMe',
                  shipTo:
                    vars.body?.shipTo ?? prevCart.shippingInfo.shipTo ?? null,
                },
              }
            : prevCart,
        vars,
      ),
    ],
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useCreatePaymentIntentMutation = makeUseMutation(
  endpoints.carts.createPaymentIntent,
  (vars) => ({
    regular: (newIntent) => [
      makeQueryUpdate(endpoints.carts.intentDetail, () => newIntent, vars),
    ],
  }),
)

export const useCreateSetupIntentMutation = makeUseMutation(
  endpoints.paymentMethods.createSetupIntent,
)
export const useCancelPaymentIntentMutation = makeUseMutation(
  endpoints.carts.cancelPaymentIntent,
  undefined,
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const intentDetailQueryKey = getEndpointKey(
        endpoints.carts.intentDetail,
        vars,
      )
      queryClient.invalidateQueries({queryKey: intentDetailQueryKey})
    },
  }),
)

export const usePayForCartMutation = makeUseMutation(
  endpoints.carts.pay,
  (vars) => ({
    regular: (updatedCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => updatedCart, vars),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_updatedCart, vars) => {
      const publicTabQueryKey = getEndpointKey(
        endpoints.publicTabs.detail,
        vars,
      )

      queryClient.invalidateQueries({queryKey: publicTabQueryKey})

      if (vars.body?.tabId != null) {
        const tabQueryKey = getEndpointKey(endpoints.tabs.detail, {
          pathParams: {
            tabId: vars.body.tabId,
          },
        })
        queryClient.invalidateQueries({queryKey: tabQueryKey})
      }
    },
    onError: (err: any, vars) => {
      if (err.response?.data?.error === 'items_sold_out') {
        const cartDetailQueryKey = getEndpointKey(endpoints.carts.detail, vars)

        queryClient.refetchQueries({queryKey: cartDetailQueryKey})
      }
    },
  }),
)

export const useApplyCartDiscountMutation = makeUseMutation(
  endpoints.carts.applyDiscount,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useResetCartDiscountMutation = makeUseMutation(
  endpoints.carts.resetDiscount,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useCreateCartItemMutation = makeUseMutation(
  endpoints.carts.createItem,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useCreateBlindCartItemMutation = makeUseMutation(
  endpoints.carts.createBlindItem,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useCreateCartFormMutation = makeUseMutation(
  endpoints.carts.createForm,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useUpdateCartFormMutation = makeUseMutation(
  endpoints.carts.updateForm,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useCreateCartTimeSlotMutation = makeUseMutation(
  endpoints.carts.createTimeSlot,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useUpdateCartItemMutation = makeUseMutation(
  endpoints.carts.updateItem,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useUpdateCartTimeSlotMutation = makeUseMutation(
  endpoints.carts.updateTimeSlot,
  (vars) => ({
    regular: (newCart) => [
      makeQueryUpdate(endpoints.carts.detail, () => newCart, vars),
    ],
  }),
)

export const useDeleteCartItemMutation = makeUseMutation(
  endpoints.carts.deleteItem,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.carts.detail,
        (prevCart) =>
          prevCart
            ? {
                ...prevCart,
                items: prevCart.items.filter(
                  (i) => i.id !== Number(vars.pathParams.itemId),
                ),
              }
            : prevCart,
        vars,
      ),
    ],
  }),
)

export const useDeleteCartFormMutation = makeUseMutation(
  endpoints.carts.deleteForm,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.carts.detail,
        (prevCart) =>
          prevCart
            ? {
                ...prevCart,
                forms: prevCart.forms.filter(
                  (f) => f.id !== Number(vars.pathParams.formId),
                ),
              }
            : prevCart,
        vars,
      ),
    ],
  }),
)

export const useDeleteCartTimeSlotMutation = makeUseMutation(
  endpoints.carts.deleteTimeSlot,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.carts.detail,
        (prevCart) =>
          prevCart
            ? {
                ...prevCart,
                time_slots: prevCart.time_slots.filter(
                  (ts) => ts.id !== Number(vars.pathParams.timeSlotId),
                ),
              }
            : prevCart,
        vars,
      ),
    ],
  }),
)
