import * as Util from '@cheddarup/util'

import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreateCategoryMutation = makeUseMutation(
  endpoints.tabCategories.create,
  (vars) => ({
    regular: (newCategory) => [
      makeQueryUpdate(
        endpoints.tabCategories.list,
        (prevCategories) =>
          prevCategories ? [...prevCategories, newCategory] : prevCategories,
        vars,
      ),
    ],
  }),
)

export const useUpdateCategoryMutation = makeUseMutation(
  endpoints.tabCategories.update,
  (vars) => ({
    regular: (newCategory) => [
      makeQueryUpdate(
        endpoints.tabCategories.list,
        (prevCategories) =>
          prevCategories?.map((c) =>
            c.id === newCategory.id ? newCategory : c,
          ),
        vars,
      ),
      makeQueryUpdate(endpoints.tabCategories.detail, () => newCategory, vars),
      makeQueryUpdate(
        endpoints.tabItems.list,
        (prevItems) =>
          prevItems?.map((i) =>
            i.category?.id === newCategory.id
              ? {
                  ...i,
                  category: {
                    id: newCategory.id,
                    name: newCategory.name,
                    options: newCategory.options,
                  },
                }
              : i,
          ),
        vars,
      ),
    ],
  }),
)

export const useDeleteCategoryMutation = makeUseMutation(
  endpoints.tabCategories.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabCategories.list,
        (prevCategories) =>
          prevCategories?.filter(
            (c) => c.id !== Number(vars.pathParams.categoryId),
          ),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabItems.list,
        (prevItems) =>
          prevItems?.filter(
            (i) => i.parent_id !== Number(vars.pathParams.categoryId),
          ),
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)

      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
    },
  }),
)

export const useCreateItemMutation = makeUseMutation(
  endpoints.tabItems.create,
  (vars) => ({
    regular: (newItem) => [
      makeQueryUpdate(
        endpoints.tabItems.list,
        (prevItems) => (prevItems ? [...prevItems, newItem] : prevItems),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                reportsAvailable: {
                  ...prevTab.reportsAvailable,
                  activeItemsCount:
                    prevTab.reportsAvailable.activeItemsCount + 1,
                },
              }
            : prevTab,
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_newItem, vars) => {
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)
      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
    },
  }),
)

export const useUpdateItemMutation = makeUseMutation(
  endpoints.tabItems.update,
  (vars) => ({
    regular: (newItem) => [
      makeQueryUpdate(
        endpoints.tabItems.list,
        (prevItems) =>
          prevItems?.map((i) => (i.id === newItem.id ? {...i, ...newItem} : i)),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabCategories.list,
        (prevCategories) =>
          prevCategories?.map((cat) =>
            cat.items?.some((i) => i.id === newItem.id)
              ? {
                  ...cat,
                  items: cat.items.map((i) =>
                    i.id === newItem.id ? newItem : i,
                  ),
                }
              : cat,
          ),
        vars,
      ),
      makeQueryUpdate(endpoints.tabItems.detail, () => newItem, vars),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_newItem, vars) => {
      const itemsSearchQueryKey = getEndpointKey(
        endpoints.tabItems.search,
        vars,
      )
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)

      queryClient.invalidateQueries({queryKey: itemsSearchQueryKey})
      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
    },
  }),
)

export const useUpdateBatchItemsMutation = makeUseMutation(
  endpoints.tabItems.updateBatch,
  (vars) => ({
    regular: (newItems) => {
      const newItemsById = Util.mapToObj(newItems, (i) => [i.id, i])

      return [
        makeQueryUpdate(
          endpoints.tabItems.list,
          (prevItems) =>
            prevItems?.map((i) =>
              newItemsById[i.id] ? {...i, ...newItemsById[i.id]} : i,
            ),
          vars,
        ),
        ...newItems.map((i) =>
          makeQueryUpdate(
            endpoints.tabItems.detail,
            (prevItem) => (prevItem ? {...prevItem, ...i} : prevItem),
            {
              pathParams: {
                tabId: vars.pathParams.tabId,
                itemId: i.id,
              },
            },
          ),
        ),
      ]
    },
  }),
  (queryClient) => ({
    onSuccess: (_newItem, vars) => {
      const itemsSearchQueryKey = getEndpointKey(
        endpoints.tabItems.search,
        vars,
      )
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)

      queryClient.invalidateQueries({queryKey: itemsSearchQueryKey})
      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
    },
  }),
)

export const useDeleteItemMutation = makeUseMutation(
  endpoints.tabItems.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabItems.list,
        (prevItems) =>
          prevItems?.filter((i) => i.id !== Number(vars.pathParams.itemId)),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabCategories.list,
        (prevCategories) =>
          prevCategories?.map((cat) =>
            cat.items?.some((i) => i.id === Number(vars.pathParams.itemId))
              ? {
                  ...cat,
                  items: cat.items.filter(
                    (i) => i.id !== Number(vars.pathParams.itemId),
                  ),
                }
              : cat,
          ),
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)
      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
    },
  }),
)

export const useUploadItemsMutation = makeUseMutation(
  endpoints.tabItems.upload,
  undefined,
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)
      const itemListQueryKey = getEndpointKey(endpoints.tabItems.list, vars)

      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
      queryClient.invalidateQueries({queryKey: itemListQueryKey})
    },
  }),
)

export const useCloneItemMutation = makeUseMutation(
  endpoints.tabItems.clone,
  (vars) => ({
    regular: (newItem) => [
      makeQueryUpdate(
        endpoints.tabItems.list,
        (prevItems) => {
          if (!prevItems) {
            return prevItems
          }

          const itemToDuplicate = prevItems.find(
            (i) => i.id === Number(vars.pathParams.itemId),
          )
          if (!itemToDuplicate) {
            return prevItems
          }

          return [
            ...prevItems.filter(
              (i) => i.parent_id !== itemToDuplicate.parent_id,
            ),
            ...prevItems
              .filter((i) => i.parent_id === itemToDuplicate.parent_id)
              .map((i) =>
                i.id === Number(vars.pathParams.itemId)
                  ? itemToDuplicate
                  : i.position < itemToDuplicate.position
                    ? i
                    : {...i, position: i.position + 1},
              ),
            newItem,
          ]
        },
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabCategories.list,
        (prevCategories) =>
          prevCategories?.map((cat) =>
            cat.id === newItem.parent_id
              ? {...cat, items: [...(cat.items ?? []), newItem]}
              : cat,
          ),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                reportsAvailable: {
                  ...prevTab.reportsAvailable,
                  activeItemsCount:
                    prevTab.reportsAvailable.activeItemsCount + 1,
                },
              }
            : prevTab,
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_newItem, vars) => {
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)
      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
    },
  }),
)

export const useAlphabetizeItemsMutation = makeUseMutation(
  endpoints.tabItems.alphabetize,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabCategories.list,
        (prevCategories) =>
          prevCategories?.map((cat) => ({
            ...cat,
            items: Util.sort(cat.items ?? [])
              .asc((i) => i.name.toLowerCase())
              .map((i, idx) => ({
                ...i,
                position: idx + 1,
              })),
          })),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabItems.list,
        (prevItems) => {
          if (!prevItems) {
            return prevItems
          }

          const parentIdToPositionMap: Record<string, number> = {}

          return Util.sort(prevItems)
            .asc((i) => i.name.toLowerCase())
            .map((item) => {
              const parentId = String(item.parent_id)

              if (parentIdToPositionMap[parentId]) {
                parentIdToPositionMap[parentId]++
              } else {
                parentIdToPositionMap[parentId] = 0
              }

              return {
                ...item,
                // biome-ignore lint/style/noNonNullAssertion:
                position: parentIdToPositionMap[parentId]!,
              }
            })
        },
        vars,
      ),
    ],
  }),
)

export const useMoveItemsMutation = makeUseMutation(
  endpoints.tabItems.move,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabItems.list,
        (prevItems) =>
          prevItems?.filter((i) => !vars.body?.tab_item_ids.includes(i.id)),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabCategories.list,
        (prevCategories) =>
          prevCategories?.filter(
            (c) =>
              !c.items?.every((i) => vars.body?.tab_item_ids.includes(i.id)),
          ),
        vars,
      ),
    ],
  }),
)

export const useAdjustPricesItemsMutation = makeUseMutation(
  endpoints.tabItems.adjustPrices,
  (vars) => {
    const getItemAmountType = (item: Api.TabItem) => {
      if (item.options.variants?.enabled) {
        return 'listing'
      }
      if (item.options.recurring?.enabled) {
        return 'recurring'
      }
      return item.amount_type
    }

    const getNewAmount = (oldAmount: number) => {
      if (vars.body?.fixed != null) {
        return vars.body.fixed
      }
      if (vars.body?.percentage != null) {
        return oldAmount * (vars.body.percentage / 100)
      }
      return oldAmount
    }

    const adjustItemPricing = (item: Api.TabItem): Api.TabItem => {
      const amountType = getItemAmountType(item)
      const newAmount = getNewAmount(item.amount ?? 0)

      switch (amountType) {
        case 'fixed':
        case 'recurring':
          if (vars.body?.basis === 'sale') {
            return {...item, amount: newAmount}
          }
          if (vars.body?.basis === 'retail') {
            return {
              ...item,
              options: {...item.options, retailPrice: newAmount},
            }
          }

          return item
        case 'listing':
          if (vars.body?.basis === 'sale') {
            return {
              ...item,
              options: {
                ...item.options,
                variants: item.options.variants
                  ? {
                      ...item.options.variants,
                      listings: item.options.variants.listings.map((l) => ({
                        ...l,
                        amount: newAmount,
                      })),
                    }
                  : item.options.variants,
              },
            }
          }
          if (vars.body?.basis === 'retail') {
            return {
              ...item,
              options: {
                ...item.options,
                variants: item.options.variants
                  ? {
                      ...item.options.variants,
                      listings: item.options.variants.listings.map((l) => ({
                        ...l,
                        retailPrice: newAmount,
                      })),
                    }
                  : item.options.variants,
              },
            }
          }

          return item
        default:
          return item
      }
    }

    return {
      optimistic: [
        makeQueryUpdate(
          endpoints.tabItems.list,
          (prevItems) =>
            prevItems
              ?.filter((i) => vars.body?.tab_item_ids.includes(i.id))
              .map((i) => adjustItemPricing(i)),
          vars,
        ),
        ...(vars.body?.tab_item_ids.map((iId) =>
          makeQueryUpdate(
            endpoints.tabItems.detail,
            (prevItem) => (prevItem ? adjustItemPricing(prevItem) : prevItem),
            {
              pathParams: {
                tabId: vars.pathParams.tabId,
                itemId: iId,
              },
            },
          ),
        ) ?? []),
      ],
    }
  },
)

export const useBulkDeleteItemsMutation = makeUseMutation(
  endpoints.tabItems.deleteBulk,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabItems.list,
        (prevItems) =>
          prevItems?.filter((i) => !vars.body?.tab_item_ids.includes(i.id)),
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)
      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
    },
  }),
)

export const useAddToWaitlistMutation = makeUseMutation(
  endpoints.tabItems.addToWaitlist,
  (vars) => ({
    regular: (newWaitlist) => [
      makeQueryUpdate(
        endpoints.tabItems.listWaitlists,
        (prevWaitlists) =>
          prevWaitlists ? [...prevWaitlists, newWaitlist] : prevWaitlists,
        vars,
      ),
    ],
  }),
)

export const useDeleteFromWaitlisttMutation = makeUseMutation(
  endpoints.tabItems.deleteFromWaitlist,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabItems.listWaitlists,
        (prevWaitlists) =>
          prevWaitlists?.filter((w) => w.id !== vars.pathParams.waitlistId),
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const itemsSearchQueryKey = getEndpointKey(
        endpoints.tabItems.search,
        vars,
      )
      const itemReportQueryKey = getEndpointKey(endpoints.tabItems.report, vars)

      queryClient.invalidateQueries({queryKey: itemsSearchQueryKey})
      queryClient.invalidateQueries({queryKey: itemReportQueryKey})
    },
  }),
)

export const useBulkDeleteFromWaitlisttMutation = makeUseMutation(
  endpoints.tabItems.deleteBatchFromWaitlist,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabItems.listWaitlists,
        (prevWaitlists) =>
          prevWaitlists?.filter((w) => !vars.body?.waitlist_ids.includes(w.id)),
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const itemsSearchQueryKey = getEndpointKey(
        endpoints.tabItems.search,
        vars,
      )
      const itemReportQueryKey = getEndpointKey(endpoints.tabItems.report, vars)

      queryClient.invalidateQueries({queryKey: itemsSearchQueryKey})
      queryClient.invalidateQueries({queryKey: itemReportQueryKey})
    },
  }),
)
