import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useDeleteCartItemMutation} from '@cheddarup/api-client'

export interface RemoveCartItemButtonProps
  extends WebUI.ButtonProps,
    Omit<React.ComponentPropsWithoutRef<'button'>, 'onClick'> {
  cart?: Api.Cart
  cartItemId: number
  collectionSlug: string
}

export const RemoveCartItemButton: React.FC<RemoveCartItemButtonProps> = ({
  cart,
  cartItemId,
  collectionSlug,
  size = 'compact',
  variant = 'outlined',
  className,
  ...restProps
}) => {
  const deleteCartItemMutation = useDeleteCartItemMutation()

  const deleteCartItem = () =>
    deleteCartItemMutation.mutateAsync({
      pathParams: {
        tabId: collectionSlug,
        cartUuid: cart?.uuid ?? '',
        itemId: cartItemId,
      },
    })

  const dynamicFormsLinkWithItem =
    cart?.forms.filter((form) => form.detail.linked_item_id === cartItemId) ??
    []

  return dynamicFormsLinkWithItem.length > 0 ? (
    <RemoveItemWithDynamicFormsAlert
      onContinue={deleteCartItem}
      linkedFormsName={dynamicFormsLinkWithItem.map(
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        (dForm) => dForm.detail.name!,
      )}
      disclosure={
        <WebUI.DialogDisclosure
          className={WebUI.cn('self-start', className)}
          size={size}
          variant={variant}
          {...restProps}
        />
      }
    />
  ) : (
    <WebUI.Button
      className={WebUI.cn('self-start', className)}
      size={size}
      variant={variant}
      loading={deleteCartItemMutation.isPending}
      onClick={deleteCartItem}
      {...restProps}
    />
  )
}

// MARK: - RemoveItemWithDynamicFormsAlert

export interface RemoveItemWithDynamicFormsAlertProps extends WebUI.AlertProps {
  onContinue: () => Promise<any>
  linkedFormsName: string[]
}

export const RemoveItemWithDynamicFormsAlert = React.forwardRef<
  WebUI.DialogInstance,
  RemoveItemWithDynamicFormsAlertProps
>(({onContinue, linkedFormsName, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    aria-label="Remove item from cart with dynamic forms alert"
    ref={forwardedRef}
    {...restProps}
  >
    <WebUI.AlertHeader>
      Are you sure you want to remove this item?
    </WebUI.AlertHeader>
    <WebUI.AlertContentView
      text={
        <div className="flex flex-col gap-5">
          <WebUI.Text className="font-light">
            This item is linked to the following forms which will also be
            removed:
          </WebUI.Text>
          <ul className="list-disc pl-4">
            {linkedFormsName.map((formName, idx) => (
              <li key={idx}>{formName}</li>
            ))}
          </ul>
        </div>
      }
      actions={
        <>
          <WebUI.AlertActionButton execute={onContinue}>
            Delete Item and Form(s)
          </WebUI.AlertActionButton>
        </>
      }
    />
  </WebUI.Alert>
))

export default RemoveCartItemButton
